@import (reference) "_variables";
@import (reference) "../mixins/_styleResets";
@import (reference) "../mixins/_icons";

header {
	padding: 30px;
	
	h1 {
		color: @color-brand;
		font-size: 60px;
		font-weight: @font-weight-bold;
		
		@media (min-width: @screen-tablet) {
			text-align: center;
		}
	}
}

main {
	display: flex;
	justify-content: center;
	background-color: @color-gray-e;
	
	padding: 0 0 95px;
	
	@media(max-width: @screen-mobile) {
		padding: 0 0 50px;
	}
	
	form {
		max-width: 600px;
		width: 100%;
		padding: 0 30px;
		
		div.loginError {
			font-family: @font-family-header;
			border: 1px solid @color-error;
			margin: 30px 0;
			padding: 12px;
			
			h4 {
				font-weight: @font-weight;
				color: @color-error;
			}
			
			.material-icons-round {
				vertical-align: middle;
				margin-right: 5px;
				
				&:before {
					.material(@material-warning-sign);
					min-width: 20px;
				}
			}
			
			div {
				font-size: @font-size-sm;
			}
		}
		
		label {
			font-size: @font-size;
			margin: 30px 0 5px;
		}
		
		input {
			width: 100%;
			height: 32px;
			padding-left: 15px;
			
			border: 1px solid @color-gray-7;
			border-radius: 2px;
			background-color: white;
		}
		
		input:focus {
			border-color: @color-link;
			color: @color-text;
			box-shadow: 0 0 2px 2px rgba(33, 52, 151, 0.25);
			background-color: white;
			outline:1px solid @color-link;
		}
		
		.login_button {
			padding: @spacing-minimum @spacing-standard;
			margin-top: @spacing-paragraph;
			min-height: revert;
			background: @color-royal;
			border: 1px solid #363B89;
			
			font-family: @font-family;
			font-weight: @font-weight-bold;
			font-size: 18px;
			line-height: 140%;
			
			&:active {
				border: 2px solid #000000;
			}
			
			&:hover {
				background: @color-salmon-light;
				border: 1px solid @color-white;
			}
		}
	}
}

.formHelp {
	display: flex;
	justify-content: space-between;
	
	label {
		display: inline;
		margin: 5px;
		font-size: 13px;
	}
	
	input {
		width: 15px;
		font-size: 16px;
		vertical-align: middle;
	}
	
	input:focus {
		outline: none;
		box-shadow: none;
	}
	
	div {
		text-shadow: none;
	}
	
	.logInHelp {
		display: flex;
		align-self: center;
		text-align: right;
		
		a {
			font-size: @font-size;
			color: @color-link-secondary;
			
			@media (max-height: @screen-tablet) {
				font-size: @font-size-xs;
			}
		}
		
		@media(max-width: @screen-tablet) {
			align-self: flex-start;
			margin: 7px 0;
		}
	}
}

.topnav {
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
